<template>
    <div data-aos="zoom-in" data-aos-once="true" data-aos-delay="100" data-aos-offset="200" data-aos-duration="400"
        class="xl:h-screen">
        <div class="hidden dark:block xl:h-[91%]">
            <video class="w-full h-full lg:max-h-fit lg:object-fill" :src="videoUrlDark" loop muted autoplay></video>
        </div>
        <div class="dark:hidden xl:h-[91%]">
            <video class="w-full h-full lg:max-h-fit lg:object-fill" :src="videoUrl" loop muted autoplay></video>
        </div>
    </div>
</template>

<script setup>
const videoUrlDark = '/NGSI_CI_Darkmode.mp4';
const videoUrl = '/NGSI_CI.mp4';
</script>