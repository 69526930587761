<template>
  <div
    class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 border-b dark:border-gray-800 max-w-7xl 3xl:max-w-8xl">
    <div class="grid gap-6 row-gap-8 lg:grid-cols-2">
      <div class="flex flex-col justify-center">
        <div class="max-w-xl mb-3">
          <h2 class="max-w-lg text-4xl font-bold text-gray-700 dark:text-gray-200 text-center md:text-left">
            {{ $t('middle-section-header') }}<br class="hidden md:block" />
            <span class="relative px-1">
              <div class="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400"></div>
              <span class="relative inline-block text-deep-purple-accent-400"></span>
            </span>
          </h2>
          <p class="font-medium mb-1 text-gray-800 dark:text-gray-200 text-xl">
            {{ $t('middle-section-title') }}
          </p>
          <p class="text-sm text-gray-700 dark:text-gray-400 line-clamp-5">
            {{ $t('middle-section-content') }}
          </p>
        </div>
        <div class="grid gap-5 row-gap-8 sm:grid-cols-2">
          <div class="  shadow-sm border-deep-purple-accent-400 ">
            <div class="h-full p-5 border rounded-xl border-gray-700">
              <h6 class="mb-2 font-semibold leading-5 text-gray-800 dark:text-gray-200">
                {{ $t('mix-service-title') }}
              </h6>
              <p class="text-sm text-gray-700 dark:text-gray-400">
                {{ $t('mix-service-description') }}
              </p>
              <button
                class="mt-3 p-3 text-center md:text-left text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-50"><nuxt-link
                  :to="localePath('/solutions/ERP-Solutions')" class="">{{ $t('link-button') }}</nuxt-link></button>
            </div>
          </div>
          <div class=" shadow-sm border-deep-purple-accent-400">
            <div class="h-full p-5 border rounded-xl border-gray-700">
              <h6 class="mb-2 font-semibold leading-5 text-gray-800 dark:text-gray-200">
                {{ $t('mix-contact-title') }}
              </h6>
              <div class="div flex">
                <p class="text-sm text-gray-700 dark:text-gray-400">
                  {{ $t('mix-contact-1') }} <span class="font-bold dark:text-white underline"><nuxt-link
                      :to="localePath('/contact')"> {{ $t('mix-contact-2') }}</nuxt-link></span> {{ $t('mix-contact-3') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full h-56 sm:h-96">
        <img data-aos="zoom-in" data-aos-once="true" data-aos-delay="100" data-aos-offset="200" data-aos-duration="400" class="object- w-full h-56 rounded shadow-lg sm:h-96 " src="/ngsi-mainpage.jpg" alt="" />
      </div>
  </div>
</div></template>