<script setup>
import BlogSection from '~~/components/BlogSection.vue';
const metaDescription = ref("Drive your business forward with NGSI's comprehensive web development, e-commerce skills, and robust IT solutions. Embrace virtualization for unmatched efficiency")
const { locale } = useI18n();
const runtimeConfig = useRuntimeConfig();
useHead({
  title: "NGSI | NextGen Solution & Innovation",
  htmlAttrs: {
    class: 'overflow-y-scroll scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-400',
    lang: locale.value
  },
  link: [{
    rel: "canonical",
    href: runtimeConfig.public.baseURL + locale.value
  }],
  meta: [{
    name: "description",
    content: metaDescription
  }]
})


const showMobileMenu = ref(false);
provide("showMobileMenu", showMobileMenu);

const onOpenMobileMenu = () => {
  showMobileMenu.value = true;
};


</script>

<template>
  <div class="min-h-screen dark:bg-gray-900">

    <MobileMenu />
    <client-only>
      <MainHeader @open-mobile-menu="onOpenMobileMenu" />
    </client-only>
    <NewVideoPlayer/>
    <HeroSection />
    <!-- <MiddleSection />
    <ExploreSolutions /> -->
    <ReferansSection />
    <MixedSection />
    <!-- <CallUs/> -->
    <Contact3 />
    <client-only>
      <CookieSection />
      <MainFooter />
    </client-only>
  </div>
</template>
